.flexColumnParent {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  width: 100%;
  flex-grow: 1;
}

.flexRowParent {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
}

.flexItem {
  flex-grow: 0;
  flex-shrink: 0;
}

.flexItemBiggest {
  flex-grow: 1;
  display: flex;
  overflow: auto;
}

.flexItemColumnEquals {
  height: 0;
  flex-grow: 1;
  overflow: hidden;
}

.flexItemRowEquals {
  width: 0;
  flex-grow: 1;
  overflow: hidden;
  text-align: center;
}
